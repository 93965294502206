/* src/App.css */

/* Standard Light Mode */
body {
  background-color: #ffffff;
  color: #000000;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Zentriert den Inhalt vertikal */
  min-height: 100vh; /* Volle Höhe des Viewports */
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box; /* Stellt sicher, dass Padding im Gesamtbreitenmaß enthalten ist */
}

.auth-box {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sanfte Schatten für besseres visuelles Feedback */
}

.auth-button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.auth-button:hover {
  background-color: #0056b3;
}

input {
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Dark Mode Styles */
@media (prefers-color-scheme: dark) {
  body {
    background-color: #121212;
    color: #e0e0e0;
  }

  .auth-box {
    background-color: #1e1e1e;
    border: 1px solid #333;
  }

  .auth-button {
    background-color: #0056b3;
  }

  .auth-button:hover {
    background-color: #003d7a;
  }

  input {
    background-color: #333;
    color: #e0e0e0;
    border: 1px solid #555;
  }
}
